import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TextArea from 'src/components/atoms/TextArea'
import Typography from 'src/components/atoms/Typography'
import Location from '../Location/'
import Separator from 'src/components/atoms/Separator'
import { nextStepAction, setBasicDataAction, setStepsCompletedAction, updateRFPAction } from 'src/state/quotation/actions'
import { selectBasicData, selectCurrentStep } from 'src/state/quotation/selectors'
import { useNavigate } from 'react-router'
import Guard from '../Guard'
import { isExist } from '../../../utils/validationsHelper'
import { BgMobile, ContainerMobile, Grid, GridItem, GroupError, TextMobile, TitleMobile } from '../../../shared/styles'
import { IdValue } from 'src/interfaces/store/quotation/interfaces'
import MultipleSelect from 'src/components/molecules/MultipleSelect'
import styled from 'styled-components'
import ContactProject from 'src/components/molecules/ContactProject'
import InputFile from 'src/components/molecules/InputFIle'
import { Attachment, FileItem } from 'src/components/molecules/InputFIle/types'
import QuotationService from 'src/services/QuotationService'
import { ERRORLABEL, ERRORLABEL_FILE_LINKS, MAX_CHARACTERS_TEXTAREA, OTHERLABEL, REACT_ENV } from 'src/utils/config'
import FooterButtons from '../../molecules/FooterButtons'
import Theme from 'src/Theme'
import FileLink from 'src/components/molecules/FileLinks'
import FieldInput from 'src/components/molecules/FieldInput'
import { selectOptions } from 'src/shared/Data'
import useRefresh from 'src/hook/useQuery/useRefresh'
import { devices } from 'src/utils'
import Img from 'src/components/atoms/Image'

const MAX_LENGTH_NAME = 50

const UpLoadHeader = styled.div`
  display:flex;
  justify-content:space-between;
`

const Wrapper = styled.div<{
  error?: boolean,
}>`
  background-color: ${Theme.palette.borderInput};
  border-radius: .5rem;
  border: 1px solid ${({ error, theme }) => error ? theme.palette.errorBorder : theme.palette.lightGrey};
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 100%;

  @media ${devices.mobileS} and (max-width: 560px){
    padding: 1rem .5rem;
  }
`
const Step1: React.FC = () => {
  const dispatch = useDispatch()
  const router = useNavigate()
  const currentStep = useSelector(selectCurrentStep)
  const { reload, setReload, data } = useRefresh('BasicData')
  const {
    id,
    intentSummary,
    location,
    other,
    projectAattachments,
    projectArea,
    projectContact,
    projectFileLinks,
    projectName,
    scanningPurpose,
  } = useSelector(selectBasicData)

  const [project, setProjectName] = useState('')
  const [projectContactData, setProjectContactData] = useState({
    name: '',
    companyName: '',
    phone: '',
  })
  const [locationMap, setLocation] = useState('')
  const [area, setArea] = useState('')
  const [summary, setSummary] = useState('')
  const [values, setValues] = useState<IdValue[]>([])
  const [otherPurpose, setPurpose] = useState('')
  const [disabled, setDisabled] = useState<boolean>(true)
  const [attachments, setAttachments] = useState<any[]>([])
  const [loadingFiles, setLoadingFiles] = useState(false)
  const [fileLinks, setFileLinks] = useState<any>([])
  const [maxFile, setMaxFile] = useState<boolean>(false)
  const [required, setRequired] = useState<boolean>(false)
  const [inputValue, setInputValue] = React.useState<string>('')
  const [fileSaveRequired, setFileSaveRequired] = React.useState<boolean>(false)

  const onChangeProjectName = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setProjectName(evt.target.value || '')
  }

  const onChangeLocation = (address?: string) => {
    setLocation(address || '')
  }

  const onChangeProjectArea = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setArea(evt.target.value || '')
  }

  const onChangeSummary = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSummary(evt.target.value || '')
  }

  const onChangeValue = (val: IdValue, type: string) => {
    if (type === 'input') return
    if (isExist(values, val.id).length) {
      const removeOption = values.filter((opt) => opt.id !== val.id)
      setValues(removeOption)
      val.value === OTHERLABEL && setPurpose('')
    } else {
      setValues([...values, val])
      if (val.id === '12') {
        const otherVal = val.value.split(':')
        otherVal.length > 1 && setPurpose(otherVal[1].trim())
      }
    }
  }

  const onChangeFiles = (arr: FileItem[]) => {
    const filesize = arr[0].size / 1024 / 1024
    if (filesize >= 10) {
      setMaxFile(true)
    } else {
      setMaxFile(false)
      setLoadingFiles(true)
      new QuotationService({
        baseURL: REACT_ENV.REACT_APP_BASE_URL,
      }).uploadFile(sessionStorage.getItem('idLanding') ?? id!, arr)
        .then(res => {
          dispatch(setBasicDataAction({
            projectAattachments: res.data,
          }))
          setAttachments([...attachments, ...res.data])
          setLoadingFiles(false)
        })
    }
  }

  const onChangeLinks = (links: string, type: string) => {
    if (type === 'delete') {
      const updlinks = fileLinks.filter((link: any) => link.value !== links)
      return setFileLinks(updlinks)
    }

    setFileLinks([
      ...fileLinks,
      {
        value: links,
      },
    ])
  }

  const onRemoveFile = (item: Attachment) => {
    const newArr = attachments?.filter((val) => val.name !== item.name)
    setAttachments(newArr as any)
    dispatch(setBasicDataAction({
      projectAattachments: newArr!.length > 0 ? newArr : null,
    }))
    const itemToDelete = attachments && attachments.find((val: any) => val.name === item.name)
    new QuotationService({
      baseURL: REACT_ENV.REACT_APP_BASE_URL,
    }).deleteFile(itemToDelete?.id)
  }

  const gotoNextStep = () => {
    if (!project || !summary || !locationMap || !values.length || (!disabled && !otherPurpose)) {
      setRequired(true)
      return
    }
    if (inputValue) {
      setFileSaveRequired(true)
      return
    }
    const updData = {
      intentSummary: summary,
      location: locationMap,
      other: otherPurpose,
      projectArea: area,
      projectContact: projectContactData,
      projectFileLinks: fileLinks,
      projectName: project,
      scanningPurpose: values,
    }
    dispatch(updateRFPAction({
      id: sessionStorage.getItem('idLanding') ?? id!,
      title: 'Basic Data',
      comment: 'Summary / Location / Project Name / Scanning Purpose',
      stepType: 'BasicData',
      data: {
        ...updData,
        projectAattachments: attachments,
      },
    }))
    dispatch(setBasicDataAction({
      id: sessionStorage.getItem('idLanding') ?? id!,
      ...updData,
    }))
    dispatch(nextStepAction())
    dispatch(setStepsCompletedAction({ id: 2 }))
    router('/quote/2')
  }

  const setInfoStep = (data: any) => {
    const {
      intentSummary,
      location,
      other,
      projectArea,
      projectContact,
      projectFileLinks,
      projectName,
      scanningPurpose,
      projectAattachments,
    } = data

    projectName && setProjectName(projectName)
    projectContact && setProjectContactData(projectContact)
    location && setLocation(location)
    projectArea && setArea(projectArea)
    intentSummary && setSummary(intentSummary)
    scanningPurpose && setValues(scanningPurpose)
    projectAattachments && setAttachments(projectAattachments)
    projectFileLinks && setFileLinks(projectFileLinks)
    other && setPurpose(other)
    other && setDisabled(false)
  }

  useEffect(() => {
    setInfoStep({
      intentSummary,
      location,
      other,
      projectArea,
      projectContact,
      projectFileLinks,
      projectName,
      scanningPurpose,
      projectAattachments,
    })
  }, [])

  useEffect(() => {
    if (!reload) return
    // eslint-disable-next-line no-prototype-builtins
    if (!data.hasOwnProperty('projectName')) return
    setInfoStep(data)
    setReload(false)
  }, [reload, data])

  return (
    <Guard height="100%">
      <TitleMobile>Welcome to Sirvey!</TitleMobile>
      <Separator size='1rem' />
      <TextMobile>Only fields marked with an askerisk (*) are required</TextMobile>
      <Grid>
        <GridItem>
          <FieldInput
            fontSize='1rem'
            label='Project Name *'
            maxlength={MAX_LENGTH_NAME}
            maxWidth='44.375rem'
            name='project-name'
            onChange={onChangeProjectName}
            placeholder={`Max. ${MAX_LENGTH_NAME} Characters`}
            type='text'
            value={project}
            withBorder
            required={required}
            sectionName='project-name'
          />
          <ContactProject
            label='Contact Information'
            fontSize='1rem'
            value={projectContactData}
            onChange={setProjectContactData}
          />
          {
            sessionStorage.getItem('REACT_APP_MAPS_KEY') &&
            <Location onChange={onChangeLocation} value={location} required={required} />
          }
          <FieldInput
            type='text'
            label='Project/Building Area:'
            name='project-area'
            fontSize='1rem'
            placeholder='Total and/or partial sqft.'
            maxWidth='44.375rem'
            minHeight='7.5rem'
            withBorder
            value={area}
            onChange={onChangeProjectArea}
            sectionName='project-area'
          />
        </GridItem>
        <GridItem>
          <TextArea
            label='Scope of Work *'
            placeholder='Provide general information about the project like number of levels, construction phase, demolition details, specific inclusions and exclusions, etc.'
            fontSize='1rem'
            value={summary}
            onInput={onChangeSummary}
            maxlength={MAX_CHARACTERS_TEXTAREA}
            required={required}
            id="scope-work"
          />
          <Wrapper error={required && !values.length}
            style={{ padding: '1rem 0 .5rem' }}>
            <Typography variant='label' fontSize='1rem' style={{ padding: '0 0 0 1rem' }}>
              Scanning Purpose (select one or more)*
            </Typography>
            <Separator size="10px" />
            <MultipleSelect
              options={selectOptions}
              values={values}
              onChange={onChangeValue}
              onChangeOther={(val: string) => setPurpose(val)}
              other={otherPurpose}
              disabled={disabled}
              setDisabled={setDisabled}
              placeholder={'To continue you must write an option'}
              required={required}
              sectionName="scanning-purpose"
            />
          </Wrapper>
          {required && !values.length &&
            <GroupError>
              <Img
                alt="icon-error"
                src={`${REACT_ENV.PUBLIC_URL}/images/icon-error.png`}
                width='1.25rem'
              />
              <Typography variant='span' color={Theme.palette.errorBorder} bold>
                {ERRORLABEL}
              </Typography>
            </GroupError>
          }
          <Wrapper>
            <UpLoadHeader>
              <Typography variant='label' fontSize='1rem'>
                Available Documentation
              </Typography>
              <Typography variant='label' bold={false} fontSize='12px' color='grey_upload'>
                Max file size 10 MB
              </Typography>
            </UpLoadHeader>
            <Separator size="10px" />
            <InputFile
              value={attachments}
              onChange={onChangeFiles}
              onRemove={onRemoveFile}
              multiple
              loadingFiles={loadingFiles}
            />
            {maxFile && <Typography variant='label' fontSize='1rem' color='error' >Max file size should be 10MB</Typography>}
          </Wrapper>
          <Wrapper error={ fileSaveRequired }>
            <Typography variant='label' fontSize='1rem'>
              File Links
            </Typography>
            <Separator size="10px" />
            <FileLink
              FileLinks={fileLinks}
              onChangeLinks={onChangeLinks}
              inputValue={inputValue}
              setInputValue={setInputValue}
              setRequired={setFileSaveRequired}
            />
            <Separator size="20px" />
          </Wrapper>
          {fileSaveRequired &&
            <GroupError>
              <Img
                alt="icon-error"
                src={`${REACT_ENV.PUBLIC_URL}/images/icon-error.png`}
                width='1.25rem'
              />
              <Typography variant='span' color={Theme.palette.errorBorder} bold>
                {ERRORLABEL_FILE_LINKS}
              </Typography>
            </GroupError>
          }
        </GridItem>
      </Grid>
      <Separator size="7rem" mobile />
      <ContainerMobile>
        <FooterButtons
          gotoNextStep={gotoNextStep}
          disabledBack={currentStep === 1}
          disabled={loadingFiles}
          label='Continue'
          currentStep={currentStep}
        />
        <BgMobile />
      </ContainerMobile>
    </Guard>
  )
}

export default Step1
