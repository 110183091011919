import { AxiosResponse } from 'axios'
import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from 'src/context/Auth'
import QuotationService from 'src/services/QuotationService'
import { Spinner } from 'src/shared/styles'
import { setBasicDataAction } from 'src/state/quotation/actions'
import { selectBasicData } from 'src/state/quotation/selectors'
import { REACT_ENV } from 'src/utils/config'
import styled from 'styled-components'

const Container = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
`

const Main = () => {
  const { auth, setAuth } = useContext(AuthContext)
  const router = useNavigate()
  const dispatch = useDispatch()
  const idLanding = window.location.pathname.replace('/', '')
  const id = useSelector(selectBasicData).id

  async function setEnvs () {
    const newObj: any = {}
    Object.entries(auth.config).map(([key, value]) => {
      new QuotationService({
        baseURL: REACT_ENV.REACT_APP_SIRVEY_KEY,
      }).getKeys(value)?.then((resp: AxiosResponse) => {
        newObj[key] = resp.data
        sessionStorage.setItem(key, value + resp.data)
      })
      return key
    })
    return newObj
  }

  useEffect(() => {
    (async function () {
      const newEnvs = await setEnvs()
      setAuth(() => {
        return {
          token: '',
          config: auth.config,
          keys: newEnvs,
        }
      })
    })()
    if (idLanding) {
      dispatch(setBasicDataAction({
        id: idLanding.replace(/[|&;$%@"<>()+,.']/g, ''),
      }))
      sessionStorage.setItem('idLanding', idLanding.replace(/[|&;$%@"<>()+,.']/g, ''))
    }
    if (!idLanding) {
      window.location.replace(REACT_ENV.REACT_APP_LANDING)
    }
    if (id) {
      setTimeout(() => {
        router('/quote/1')
      }, 3000)
    }
  }, [id])

  return (
    <Container>
        <Spinner />
    </Container>
  )
}

export default Main
