/* eslint-disable no-undef */
import React, { useCallback, useContext, useEffect, useState } from 'react'
import Typography from 'src/components/atoms/Typography'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import Search from 'src/components/molecules/Search'
import { LocationProps } from './types'
import Geocode from 'react-geocode'
import {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete'
import { MapWrapper } from './styles'
import { AuthContext } from 'src/context/Auth'
import { ERRORLABEL, REACT_ENV } from 'src/utils/config'
import { GroupError } from 'src/shared/styles'
import Img from 'src/components/atoms/Image'
import Theme from 'src/Theme'

const containerStyle = {
  borderRadius: '.5rem',
  height: '20.5rem',
  width: '100%',
}

const Location: React.FC<LocationProps> = ({
  onChange,
  value,
  required,
}: LocationProps) => {
  const { auth } = useContext(AuthContext)
  const [libraries] = useState<('drawing' | 'geometry' | 'localContext' | 'places' | 'visualization')[]>(['places'])
  const { isLoaded, loadError } = useJsApiLoader({
    id: 'g-map-script',
    googleMapsApiKey: `${sessionStorage.getItem('REACT_APP_MAPS_KEY')?.replace(auth.config.REACT_APP_MAPS_KEY, '')}`,
    libraries: libraries,
  })

  Geocode.setApiKey(`${sessionStorage.getItem('REACT_APP_MAPS_KEY')?.replace(auth.config.REACT_APP_MAPS_KEY, '')}`)

  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [mapLoad, setMapLoad] = useState(false)
  const [markers, setMarkers] = useState<{
    lat: number,
    lng: number,
    time: Date
  }>({
    lat: 41.850033,
    lng: -100.6500523,
    time: new Date(),
  })
  const [address, setAddress] = useState<string>(value ?? '')

  const TemporalGeocoderAPI = async (address: string | undefined) => {
    if (!address) return
    return await fetch(
      `${REACT_ENV.REACT_APP_MAP_URL}/search?text=${encodeURI(
        address.replace(/[#-]/g, ' ').trim(),
      )}&apiKey=${sessionStorage.getItem('REACT_APP_API_KEY')?.replace(auth.config.REACT_APP_API_KEY, '')}`,
      { method: 'GET' },
    ).then((response) => response.json())
  }

  const onLoadMap = (map: google.maps.Map) => {
    setMap(map)
    setMapLoad(true)
    map.setCenter({ lat: 41.850033, lng: -100.6500523 })

    if (value) {
      TemporalGeocoderAPI(value).then((data) => {
        if (data.features && data.features.length) {
            map!.setCenter({
              lat: data.features[0].properties.lat,
              lng: data.features[0].properties.lon,
            })
            map.setZoom(15)
        }
      })
    }
  }

  const onMapClick = useCallback((event) => {
    Geocode.fromLatLng(event.latLng.lat(), event.latLng.lng()).then(
      (response) => {
        const addr = response.results[0].formatted_address
        sessionStorage.setItem('REACT_APP_LOCATION', addr)
        setAddress(addr)
        onChange && onChange(addr)
      },
      (error) => {
        console.error(error)
      },
    )
    setMarkers(
      {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
        time: new Date(),
      },
    )
  }, [address, setAddress])

  const doGetMapInfo = async (address?: string) => {
    address && sessionStorage.setItem('REACT_APP_LOCATION', address)
    return TemporalGeocoderAPI(address).then((data) => {
      if (data.features && data.features.length > 0) {
        map?.panTo({
          lat: data.features[0].properties.lat,
          lng: data.features[0].properties.lon,
        })
        map?.setZoom(14)
        setMarkers(
          {
            lat: data.features[0].properties.lat,
            lng: data.features[0].properties.lon,
            time: new Date(),
          },
        )
      }
    })
  }

  const onSearch = (address?: string) => {
    if (address) {
      doGetMapInfo(address).then(() => {
        onChange && onChange(address)
      })
    }
  }

  const panTo = useCallback(({ lat, lng }) => {
    map && map?.panTo({ lat, lng })
    map && map?.setZoom(14)
  }, [address, setAddress, value])

  function initMarket (value: string, onLoad?: boolean) {
    if (!value) return
    try {
      geocodeByAddress(value).then(
        results => getLatLng(results[0]),
      ).then(latLng => {
        panTo(latLng)
        setMarkers(
          {
            lat: latLng.lat,
            lng: latLng.lng,
            time: new Date(),
          },
        )
      })
      if (onLoad) {
        setAddress(value)
        onSearch(value)
      }
    } catch (e) {
      console.warn(e)
      doGetMapInfo(value).then(() => {
        onChange && onChange(address)
      })
    }
  }

  const handleSelect = async (value: any) => {
    value && sessionStorage.setItem('REACT_APP_LOCATION', value)
    const results = await geocodeByAddress(value)
    const latlng = await getLatLng(results[0])
    setAddress(value)
    panTo(latlng)
  }

  useEffect(() => {
    initMarket(address)
  }, [address])

  useEffect(() => {
    const location = sessionStorage.getItem('REACT_APP_LOCATION')
    if (mapLoad && location) {
      initMarket(location, true)
    }
  }, [mapLoad])

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }

  return (
    <>
    <MapWrapper error={required && !address}>
      <Typography variant='label' fontSize='1rem'>
        Project Location *
      </Typography>
      {isLoaded && <div style={{ position: 'relative' }}>
        <Search
          panTo={panTo}
          location={address ?? sessionStorage.getItem('REACT_APP_LOCATION')}
          onKeyUp={onSearch}
          onChange={onChange}
          placeholder='Type project address or click over the map to select exact location'
          onSelect={handleSelect}
        />
      </div>}
      <div className='g-map'>
        {isLoaded && (
          <>
            <GoogleMap
              onLoad={onLoadMap}
              mapContainerStyle={containerStyle}
              zoom={5}
              options={{
                fullscreenControl: false,
                mapTypeControl: false,
                streetViewControl: false,
              }}
              onClick={onMapClick}
            >
              <Marker
                  key={`${markers.lat}-${markers.lng}`}
                  position={{ lat: markers.lat, lng: markers.lng }}
              />
            </GoogleMap>
          </>
        )}
      </div>
    </MapWrapper>
    {required && !address &&
      <GroupError>
        <Img
          alt="icon-error"
          src={`${REACT_ENV.PUBLIC_URL}/images/icon-error.png`}
          width='1.25rem'
        />
        <Typography variant='span' color={Theme.palette.errorBorder} bold>
          {ERRORLABEL}
        </Typography>
      </GroupError>
      }
    </>
  )
}

export default Location
